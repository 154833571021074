export type AppButtonProps = {
    caption?: string,
    disabled?: boolean,
    color?: string,
    className?: string,
    onClick: () => void,
}

export const AppButton = (props: AppButtonProps) => {
    let {caption, disabled, color, className, onClick} = props

    caption = caption ?? ''
    disabled = disabled ?? false

    return (
        <div className={className} >
            <div className="app-button-outer" onClick={()=> onClick()}>
                {caption}
            </div>
        </div>
    )
}