import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { PasswordForm } from './components/PasswordForm';
import { isPassword } from './utils/utils';
import { _changePasswordFlamingoSample } from './services/PasswordService';
import { CustomResponse } from './services/CustomResponse';
import queryString from 'query-string'

type AppUI = {
  email: string,
  password: string,
  passwordConfirm: string,
  changeKey: string,
}

const initialState: AppUI = {
  email: 'aaa@bbb.com',
  password: '',
  passwordConfirm: '',
  changeKey: '',
}

function App() {
  const [{email, password, passwordConfirm, changeKey}, setState] = useState<AppUI>(initialState)

  useEffect(()=> {
    const parsed = queryString.parse(window.location.search)
    let tempEmail: string = parsed.email as string
    tempEmail = tempEmail.replace('%20', ' ')
    setState((prev) => ({...prev, email: tempEmail, changeKey: parsed.change_key as string}))
  }, [])

  const checkPassword = (password: string, passwordConfirm: string, changeKey: string): boolean => {
    if (password.trim() == '') {
      alert('Please input password')
      return false
    }

    if (password != passwordConfirm) {
      alert('password does not match password confirm')
      return false
    }

    if (isPassword(password) == false) {
      alert('password must be 8 character/ alphanumeric')
      return false
    }

    if (changeKey.trim() == '') {
      alert('Change Key is invalid')
      return false
    }

    return true
  }

  const execPasswordChange = async(email: string, password: string, passwordConfirm: string, changeKey: string) => {
    if (checkPassword(password, passwordConfirm, changeKey) == false) {
      return
    }

    const result: CustomResponse = await _changePasswordFlamingoSample(email,changeKey,password)
    if (result.success == false) {
      alert('Failed to change password\n' )
      console.log(result.error)
      return
    }

    alert('Password is changed completely')

  }

  return (
    <div className="App">
      <PasswordForm 
        title={'Flamingo Sample'} 
        email={email} 
        password={password} 
        passwordConfirm={passwordConfirm}
        onChangePassword={(text) => { setState((prev) => ({...prev, password: text})) }}
        onChangePasswordConfirm={(text) => { setState((prev) => ({...prev, passwordConfirm: text})) }}
        onExecute={()=> execPasswordChange(email, password, passwordConfirm, changeKey)}  
      />

    </div>
  );
}

export default App;
