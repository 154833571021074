import { createClient } from "../utils/utils";
import { CustomResponse } from "./CustomResponse";
import { gql } from 'graphql-request'

export const _changePasswordFlamingoSample = async(email: string, changekey: string, password: string): Promise<CustomResponse> => {
    const client = createClient()

    const query = gql`
        mutation updatePasswordForForget($email: String!, $password: String!, $change_key: String!) {
            updatePasswordForForget(email: $email, password: $password, change_key: $change_key)
        }
    `

    try {
        const result = await client.request(query, {email: email, password: password, change_key: changekey})
        return Promise.resolve({success: result.updatePasswordForForget} as CustomResponse)
    }   
    catch(e: any) {
        return Promise.resolve({error: e.message} as CustomResponse)
    }
}

 