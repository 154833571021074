import { GraphQLClient } from "graphql-request"
import ENV from "../env/env"

export const isPassword = (text: string): boolean => {
    // Minimum eight characters, at least one letter and one number
    const passReg = /[A-Za-z\d]{8,}$/
    const match = text.match(passReg)
    return Boolean(match)
}


export const createClient = () : any => {
    const hostUrl = false ? ENV.COMMON_DEVELOPMENT_CONFIG.hostUrl : ENV.COMMON_PRODUCTION_CONFIG.hostUrl
    const client = new GraphQLClient(hostUrl, {
      headers: {
      },
    });
  
    return client;
}
