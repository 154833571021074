export type InputBoxProps = {
    caption?: string,
    disabled?: boolean,
    placeHolder?: string,
    required?: boolean,
    password?: boolean,
    value: string | undefined,
    className?: string,
    onChange?: (text: string) => void,
}

export const InputBox = (props: InputBoxProps) => {
    let {caption, disabled, placeHolder, required, password, value, className, onChange} = props

    caption = caption ?? ''
    required = required ?? false
    disabled = disabled ?? false
    password = password ?? false

    return (
        <div className={className} >
            <label className="inputbox-label">{caption}</label><label className="inputbox-label">{required ? ' 必須' : ''}</label> 
            <div className="inputbox-input-outer">
                <input className="inputbox-input" type={password ? 'password' : 'text'} value={value} 
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        if (onChange != undefined){
                            onChange(e.target.value)
                        }
                    }} 
                />
            </div>
        </div>
    )
}