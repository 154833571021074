import { AppButton } from "./AppButton"
import { InputBox } from "./InputBox"

export type PasswordFormProps = {
    title: string,
    email: string,
    password?: string,
    passwordConfirm?: string,
    onChangePassword: (text: string) => void,
    onChangePasswordConfirm: (text: string) => void,
    onExecute: () => void,
}

export const PasswordForm = (props: PasswordFormProps) => {
    let {title, email, password, passwordConfirm, onChangePassword, onChangePasswordConfirm, onExecute} = props

    password = password ?? ''
    passwordConfirm = passwordConfirm ?? ''

    return (
        <div className="password-form-outer">
            <label className="password-form-title">{title}</label> 
            <div className="password-form-outer">
                <InputBox caption="Your Email" disabled={true} value={email} className="password-form-inputbox-outer" />
                <InputBox caption="New Password" value={password} password={true} className="password-form-inputbox-outer" 
                    onChange={(text: string) => onChangePassword(text)} />
                <InputBox caption="Password Confirm"  value={passwordConfirm} password={true} className="password-form-inputbox-outer" 
                    onChange={(text: string) => onChangePasswordConfirm(text)} />
                
                <div className="password-form-buttons">
                    <AppButton caption="Execute" 
                        onClick={()=> onExecute()}
                    />
                </div>
            </div>
        </div>
    )
}