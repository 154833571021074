const ENV = {
    COMMON_DEVELOPMENT_CONFIG: {
        hostUrl: 'http://192.168.0.14:4000',
        hostUrl2: 'https://sample.loveyourself-asyouare.com/graphql',
    },
    COMMON_PRODUCTION_CONFIG: {
        hostUrl: 'https://sample.loveyourself-asyouare.com/graphql',
    },
}

export default ENV
